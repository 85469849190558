import packageJson from '../../package.json'

export const environment = process.env.REACT_APP_MODE
export const secretKey = process.env.REACT_APP_SECRET_KEY ?? 'unaChiaveSegreta'
export const authorization = process.env.REACT_APP_AUTHORIZATION ?? 'authorization'
export const mainColor = '#FF9F0A'
export const secondaryColor = '#1E1E1E'
export const Version = packageJson.version
export const kindeClientID = process.env.REACT_APP_KINDE_CLIENT_ID
// check if we want to throw an error and turn off FE container
export const kindeDomain = process.env.REACT_APP_KINDE_DOMAIN
export const kindeRedirect = process.env.REACT_APP_KINDE_REDIRECT_URL
export const baseURL = process.env.REACT_APP_BASE_URL
