import { createSlice } from '@reduxjs/toolkit'
import { menuInitialState } from '../Utils/store'

export const menu = createSlice({
  name: 'menu',
  initialState: menuInitialState,
  reducers: {
    clearMenuState: () => menuInitialState,
    clearErrorMessage: (state) => {
      state.errorMessage = ''
    },
    setComponent: (state, action: { payload: Component }) => {
      state.component = action.payload
    },
    setErrorMessage: (state, action: { payload: string }) => {
      state.errorMessage = action.payload
    }
  }
})

export const {
  clearMenuState,
  clearErrorMessage,
  setErrorMessage,
  setComponent
} = menu.actions

export const selectErrorMessage = (state: State): State['menuInfo']['errorMessage'] => state.menuInfo.errorMessage
export const selectComponent = (state: State): State['menuInfo']['component'] => state.menuInfo.component
export const selectMenuStatus = (state: State): State['menuInfo']['menuStatus'] => state.menuInfo.menuStatus
