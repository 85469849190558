import { type TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux'
import { type Dispatch, type ThunkDispatch, type UnknownAction } from '@reduxjs/toolkit'
import type store from '../Store'
import * as ls from './ls'

export const loadState = (): State => {
  try {
    const serializedStore = ls.get('xcue-web')

    if (serializedStore === null || serializedStore === undefined) {
      return {
        userInfo: userInitialState,
        menuInfo: menuInitialState,
        customTracksInfo: customTracksInitialState,
        mixesInfo: mixesInitialState
      }
    }

    return {
      ...serializedStore
    }
  } catch (e) {
    return {
      userInfo: userInitialState,
      menuInfo: menuInitialState,
      customTracksInfo: customTracksInitialState,
      mixesInfo: mixesInitialState
    }
  }
}

export const saveState = (state: State): boolean => {
  const { ...stateToSave } = state
  try {
    ls.set('xcue-web', stateToSave)

    return ls.has('xcue-web')
  } catch (e) {
    return false
  }
}

export const userInitialState: UserStore = {
  authStatus: 'idle',
  errorMessage: ''
}

export const menuInitialState: MenuStore = {
  menuStatus: 'idle',
  component: 'idle',
  errorMessage: ''
}

export const customTracksInitialState: CustomTracksStore = {
  getStatus: 'idle',
  errorMessage: '',
  tracks: []
}

export const mixesInitialState: MixesStore = {
  getStatus: 'idle',
  postStatus: 'idle',
  errorMessage: '',
  mixes: [],
  mix: {
    mix_id: 0,
    mix_datetime: '',
    a_track_id: 0,
    b_track_id: 0,
    a_offset: null,
    b_offset: null,
    mix_type: null,
    weight: null,
    delta_danceability: null,
    delta_energy: null,
    delta_BPM: null,
    delta_genre: null,
    direction: null,
    emotion: null,
    a_track_title: null,
    b_track_title: null
  }
}

export const useAppSelector: TypedUseSelectorHook<State> = useSelector
export const useAppDispatch = (): ThunkDispatch<{
  userInfo: UserStore
}, undefined, UnknownAction> & Dispatch<UnknownAction> => useDispatch<typeof store.dispatch>()

export const formatThunkError = (e: unknown, fallback: string): string =>
  Boolean((e as any).response.data)
    ? toString((e as any).response.data, fallback)
    : toString(e, fallback)

const toString = (data: unknown, fallback: string): string => {
  if (typeof data === 'string') return data
  if (typeof data === 'object') {
    if (data !== null && 'message' in data) { return toString(data.message, fallback) }
    return fallback
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (data as unknown as any).toString()
  }
}
