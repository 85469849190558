import { createSlice } from '@reduxjs/toolkit'
import { userInitialState } from '../Utils/store'

export const user = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    clearUserState: () => userInitialState,
    clearErrorMessage: (state) => {
      state.errorMessage = ''
    },
    setErrorMessage: (state, action: { payload: string }) => {
      state.errorMessage = action.payload
    }
  }
})

export const {
  clearUserState,
  clearErrorMessage,
  setErrorMessage
} = user.actions

export const selectErrorMessage = (state: State): State['userInfo']['errorMessage'] => state.userInfo.errorMessage
