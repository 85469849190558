/* eslint-disable @typescript-eslint/no-misused-promises */
import { type FC } from 'react'
import { useMediaQuery, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from '@mui/material'
import { useAppSelector } from '../Utils/store'
import { selectMix } from '../Store/mixes'

interface SuccessDialogProps {
  showSuccessDialog: boolean
  goToMix: () => Promise<void>
  goToMixes: () => Promise<void>
}

const SuccessDialog: FC<SuccessDialogProps> = ({ goToMix, goToMixes, showSuccessDialog }) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const mix = useAppSelector(selectMix)

  return (
    <Dialog
      fullScreen={isSmallScreen}
      open={showSuccessDialog}
      onClose={goToMix}
    >
      <DialogTitle>Success</DialogTitle>
      <DialogContent>
        <Typography>Your mix has been successfully created!</Typography>
        <Typography sx={{ marginTop: 2 }}>
          <strong>Mix Details:</strong>
        </Typography>
        <Typography>Offset A: {mix.a_offset ?? 'Unset'}</Typography>
        <Typography>Offset B: {mix.b_offset ?? 'Unset'}</Typography>
        <Typography>Mix Type: {mix.mix_type ?? 'Unset'}</Typography>
        <Typography>Weight: {mix.weight ?? 'Unset'}</Typography>
        <Typography>Delta Danceability: {mix.delta_danceability ?? 'Unset'}</Typography>
        <Typography>Delta Energy: {mix.delta_energy ?? 'Unset'}</Typography>
        <Typography>Delta BPM: {mix.delta_BPM ?? 'Unset'}</Typography>
        <Typography>Delta Genre: {mix.delta_genre ?? 'Unset'}</Typography>
        <Typography>Direction: {mix.direction !== null ? (mix.direction ? 'Forward' : 'Backward') : 'Unset'}</Typography>
        <Typography>Emotion: {mix.emotion ?? 'Unset'}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={goToMix}>Close</Button>
        <Button onClick={goToMixes}>Go to Mixes</Button>
      </DialogActions>
    </Dialog>
  )
}

export default SuccessDialog
