import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { customTracksInitialState, formatThunkError } from '../Utils/store'
import { retrieveCustomTracks } from '../Api/customTracks'

interface BaseThunk {
  token: string
}

export const getCustomTracks = createAsyncThunk(
  '/custom_tracks (GET)',
  async ({ token }: BaseThunk, thunkApi) => {
    try {
      const response = await retrieveCustomTracks(token)

      return response.data
    } catch (e) {
      const error = formatThunkError(e, 'getCustomTracks error')

      return thunkApi.rejectWithValue(error)
    }
  }
)

export const customTracks = createSlice({
  name: 'customTracks',
  initialState: customTracksInitialState,
  reducers: {
    clearCustomTracksState: () => customTracksInitialState,
    clearGetAllStatus: (state) => {
      state.getStatus = 'idle'
    },
    clearErrorMessage: (state) => {
      state.errorMessage = ''
    },
    setErrorMessage: (state, action: { payload: string }) => {
      state.errorMessage = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomTracks.pending, (state) => {
      state.getStatus = 'loading'
    })
    builder.addCase(getCustomTracks.rejected, (state, action) => {
      state.errorMessage = Boolean(action.error) && typeof action.error === 'string'
        ? action.error
        : action.payload as string
      state.tracks = customTracksInitialState.tracks
      state.getStatus = 'error'
    })
    builder.addCase(getCustomTracks.fulfilled, (state, action) => {
      state.tracks = action.payload
      state.getStatus = 'success'
    })
  }
})

export const {
  clearCustomTracksState,
  clearGetAllStatus,
  clearErrorMessage,
  setErrorMessage
} = customTracks.actions

export const selectGetCustomTracksStatus = (state: State): State['customTracksInfo']['getStatus'] => state.customTracksInfo.getStatus
export const selectGetCustomTracksErrorMessage = (state: State): State['customTracksInfo']['errorMessage'] => state.customTracksInfo.errorMessage
export const selectCustomTracks = (state: State): State['customTracksInfo']['tracks'] => state.customTracksInfo.tracks
