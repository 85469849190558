import { useCallback, type FC } from 'react'
import { useTheme, CssBaseline, Box } from '@mui/material'
import { useKindeAuth } from '@kinde-oss/kinde-auth-react'
import { useAppDispatch, useAppSelector } from '../Utils/store'
import { selectComponent, setComponent } from '../Store/menu'
import HomeComponent from '../Components/Home'
import ImageLayout from '../Components/ImageLayout'
import Loader from '../Components/Loader'
import LeftMenu from '../Components/LetfMenu'
import UserPage from '../Components/UserPage'
import NewMix from './NewMix'
import CustomTracks from '../Components/CustomTracks'
import Mixes from '../Components/Mixes'
import * as ls from '../Utils/ls'

const Home: FC = () => {
  const theme = useTheme()
  const { logout, isLoading } = useKindeAuth()
  const dispatch = useAppDispatch()
  let Component: JSX.Element = <Loader />
  const component = useAppSelector(selectComponent)

  const goToMix = useCallback(async () => {
    dispatch(setComponent('mix'))
  }, [dispatch])

  const goToUserinfo = useCallback(async () => {
    dispatch(setComponent('user'))
  }, [dispatch])

  const goToCustomTracks = useCallback(async () => {
    dispatch(setComponent('tracks'))
  }, [dispatch])

  const goToMixes = useCallback(async () => {
    dispatch(setComponent('mixes'))
  }, [dispatch])

  const handleLogOut = useCallback(async () => {
    dispatch(setComponent('idle'))
    await logout()

    ls.del('xcue-web')
  }, [dispatch, logout])

  if (isLoading) {
    return <Loader />
  }

  switch (component) {
    case 'idle':
      Component = <HomeComponent />
      break
    case 'mix':
      Component = <NewMix />
      break
    case 'user':
      Component = <UserPage />
      break
    case 'tracks':
      Component = <CustomTracks />
      break
    case 'mixes':
      Component = <Mixes />
      break
  }

  return <ImageLayout
    style={{
      backgroundColor: theme.palette.secondary.main,
      height: '100vh',
      width: '100vw',
      flexDirection: 'row'
    }}
  >
    <CssBaseline />
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        width: '100vw',
        backgroundColor: theme.palette.secondary.main
      }}
    >
      <LeftMenu handleLogOut={handleLogOut} goToMixes={goToMixes} goToUserinfo={goToUserinfo} goToCustomTracks={goToCustomTracks} goToMix={goToMix} />
      {Component}
    </Box>
  </ImageLayout>
}

export default Home
