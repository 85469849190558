import { type FC, type Dispatch, type SetStateAction } from 'react'
import { type GridColDef, DataGrid, gridClasses } from '@mui/x-data-grid'
import { alpha, useTheme } from '@mui/material'

interface CompactTableProps {
  rows: any[]
  columns: GridColDef[]
  setSelectedTrackId: Dispatch<SetStateAction<number | null>>
  selectionModel: number[]
  setSelectionModel: Dispatch<SetStateAction<number[]>>
}

const CompactTable: FC<CompactTableProps> = ({ columns, rows, setSelectedTrackId, setSelectionModel, selectionModel }) => {
  const theme = useTheme()

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      disableColumnMenu
      disableColumnSelector
      disableMultipleRowSelection
      density="compact"
      hideFooter
      onRowClick={(params) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setSelectedTrackId(params.row.id)
        setSelectionModel([params.row.id])
      }}
      rowSelectionModel={selectionModel}
      sx={{
        height: '100%',
        bgcolor: theme.palette.secondary.main,
        [`& .${gridClasses.row}`]: {
          bgcolor: theme.palette.secondary.main,
          color: theme.palette.primary.main,
          '&:hover': {
            bgcolor: alpha(theme.palette.primary.main, 0.16) // Colore semi-trasparente al passaggio del mouse
          },
          '&.Mui-selected': {
            bgcolor: alpha(theme.palette.primary.main, 0.08), // Colore più intenso per la selezione
            '&:hover': {
              bgcolor: alpha(theme.palette.primary.main, 0.16) // Colore ancora più visibile durante l'hover su riga selezionata
            }
          }
        },
        '& .MuiDataGrid-columnHeaders': {
          bgcolor: `${theme.palette.secondary.main} !important`,
          color: theme.palette.primary.main
        },
        '& .MuiDataGrid-row--borderBottom': {
          bgcolor: `${theme.palette.secondary.main} !important`,
          color: theme.palette.primary.main
        },
        [`& .${gridClasses.cell}`]: {
          borderColor: theme.palette.secondary.light
        },
        '--DataGrid-rowBorderColor': theme.palette.secondary.light,
        borderColor: theme.palette.secondary.light,
        '& .MuiSvgIcon-root': {
          color: theme.palette.primary.main
        }
      }}
    />
  )
}

export default CompactTable
