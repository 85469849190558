import { type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme, Box, Typography, Avatar, Paper } from '@mui/material'
import { useKindeAuth } from '@kinde-oss/kinde-auth-react'
import PersonIcon from '@mui/icons-material/Person'
import Loader from './Loader'
import ImageLayout from './ImageLayout'

const UserPage: FC = () => {
  const { t } = useTranslation()
  const { getUser, isLoading } = useKindeAuth()
  const theme = useTheme()
  const user = getUser()

  if (isLoading) {
    return <Loader />
  }

  return <ImageLayout
    data-testid="userPage-component"
    style={{
      height: '100vh',
      width: '100vw',
      justifyContent: 'center',
      backgroundColor: theme.palette.secondary.light
    }}
  >
    <Box
      component={Paper}
      elevation={10}
      data-testid="userPage-component"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'space-between',
        width: '35%',
        maxWidth: '600px',
        padding: '4%',
        borderRadius: '5%',
        minHeight: '55%',
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.primary.main
      }}
    >
      <Box display='flex' flexDirection='column' alignItems='center' height='100%' data-testid="userPage-box" width='100%' justifyContent='center' gap='2vh'>
        {(user.picture != null && !user.picture.includes('blank'))
          ? <Avatar
            src={user.picture}
            alt={`${user.given_name} ${user.family_name}`}
            component={Paper}
            elevation={5}
            sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.secondary.main }}
            data-testid="user-avatar"
          />
          : <Avatar
            sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.secondary.main }}
            component={Paper}
            elevation={5}
            data-testid="no-user-avatar"
          >
            <PersonIcon />
          </Avatar>
        }
        <Box
          display="flex"
          width="100%"
          marginTop='3vh'
          flexDirection="column"
        >
          <Box display="flex" flexDirection="column" gap="1vh" alignItems="flex-start">
            <Typography variant="subtitle1">{t('user.givenName')}: {user.given_name ?? t('user.notAvailable')}</Typography>
            <Typography variant="subtitle1">{t('user.familyName')}: {user.family_name ?? t('user.notAvailable')}</Typography>
            <Typography variant="subtitle1">{t('user.email')}: {user.email ?? t('user.notAvailable')}</Typography>
            <Typography variant="subtitle1">{t('user.id')}: {user.id ?? t('user.notAvailable')}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  </ImageLayout>
}

export default UserPage
