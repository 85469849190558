import { type EnhancedStore, type StoreEnhancer, type ThunkDispatch, type Tuple, type UnknownAction, configureStore } from '@reduxjs/toolkit'
import { loadState, saveState } from '../Utils/store'
import { user } from './users'
import { menu } from './menu'
import { customTracks } from './customTracks'
import { mixes } from './mixes'

const setupStore = (state: State): EnhancedStore<State, UnknownAction, Tuple<[StoreEnhancer<{
  dispatch: ThunkDispatch<State, undefined, UnknownAction>
}>, StoreEnhancer]>> =>
  configureStore({
    reducer: {
      userInfo: user.reducer,
      menuInfo: menu.reducer,
      customTracksInfo: customTracks.reducer,
      mixesInfo: mixes.reducer
    },
    preloadedState: state
  })

const store = setupStore(loadState())

store.subscribe(() => saveState(store.getState()))

export default store
