import { type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme, Box, Typography, Paper } from '@mui/material'
import ImageLayout from './ImageLayout'
import Logo from '../Images/logo512.png'

const Home: FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()

  return <ImageLayout
    data-testid="userPage-component"
    style={{
      height: '100vh',
      width: '100vw',
      justifyContent: 'center',
      backgroundColor: theme.palette.secondary.light
    }}
  >
    <Box
      component={Paper}
      elevation={10}
      data-testid="home-component"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'space-between',
        width: '35%',
        maxWidth: '600px',
        padding: '4%',
        borderRadius: '5%',
        minHeight: '55%',
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.primary.main
      }}
    >
      <Box display="flex" width="100%" justifyContent="center">
        <Typography variant="h6" data-testid="home-title">
          {t('home.title')}
        </Typography>
      </Box>
      <img src={Logo} height='200px' style={{ borderRadius: '5%' }} />
      <Box display="flex" width="100%" justifyContent="space-between" flexDirection="column" gap="4vh">
        <Box display="flex" flexDirection="column" gap="1vh">
          <Typography alignSelf="center">{t('home.welcome')}</Typography>
        </Box>
      </Box>
    </Box>
  </ImageLayout>
}

export default Home
