import { useState, type FC } from 'react'
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, useTheme, alpha } from '@mui/material'
import { AccountCircle, MusicNote, Logout } from '@mui/icons-material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faMusic } from '@fortawesome/free-solid-svg-icons'
import { useAppSelector } from '../Utils/store'
import { selectComponent } from '../Store/menu'

interface DrawerItem {
  id: string
  icon: React.ReactNode
  label: string
  onClick: () => void
}

interface LeftMenuProps {
  handleLogOut: () => Promise<void>
  goToUserinfo: () => Promise<void>
  goToMixes: () => Promise<void>
  goToCustomTracks: () => Promise<void>
  goToMix: () => Promise<void>
}

const LeftMenu: FC<LeftMenuProps> = ({ handleLogOut, goToUserinfo, goToMixes, goToCustomTracks, goToMix }) => {
  const theme = useTheme()
  const component = useAppSelector(selectComponent)
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const items: DrawerItem[] = [
    {
      id: 'user',
      icon: <AccountCircle />,
      label: 'User Info',
      onClick: () => {
        void goToUserinfo()
      }
    },
    {
      id: 'mix',
      icon: <FontAwesomeIcon icon={faCirclePlus} />,
      label: 'Create New Mix',
      onClick: () => {
        void goToMix()
      }
    },
    {
      id: 'mixes',
      icon: <MusicNote />,
      label: 'Mixes',
      onClick: () => {
        void goToMixes()
      }
    },
    {
      id: 'tracks',
      icon: <FontAwesomeIcon icon={faMusic} />,
      label: 'Custom Tracks',
      onClick: () => {
        void goToCustomTracks()
      }
    },
    {
      id: 'logout',
      icon: <Logout />,
      label: 'Logout',
      onClick: () => {
        void handleLogOut()
      }
    }
  ]
  const selectionColor = alpha(theme.palette.primary.main, 0.08)

  return <Drawer
    variant="permanent"
    sx={{
      width: isExpanded ? 200 : 60,
      flexShrink: 0,
      '& .MuiDrawer-paper': {
        width: isExpanded ? 200 : 60,
        transition: 'width 0.2s ease',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
        overflowX: 'hidden',
        borderRight: `1px solid ${theme.palette.secondary.light}`
      }
    }}
    onMouseEnter={() => { setIsExpanded(true) }}
    onMouseLeave={() => { setIsExpanded(false) }}
  >
    <List sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {
        items.slice(0, 4).map(({ id, icon, label, onClick }) => (
          <ListItem
            key={id}
            onClick={onClick}
            sx={{
              padding: '10px 20px',
              justifyContent: isExpanded ? 'flex-start' : 'center',
              cursor: 'pointer',
              borderBottom: `1px solid ${theme.palette.secondary.light}`,
              backgroundColor: component === id ? selectionColor : theme.palette.secondary.main,
              '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, 0.15)
              }
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: isExpanded ? '40px' : 'unset',
                color: theme.palette.primary.main,
                justifyContent: 'center'
              }}
            >
              {icon}
            </ListItemIcon>
            {isExpanded && <ListItemText primary={label} />}
          </ListItem>
        ))
      }
      <Divider sx={{ margin: 'auto 0 0 0', backgroundColor: theme.palette.secondary.light }} />
      <ListItem
        onClick={items[4].onClick}
        sx={{
          padding: '10px 20px',
          justifyContent: isExpanded ? 'flex-start' : 'center',
          cursor: 'pointer',
          borderTop: `1px solid ${theme.palette.secondary.light}`
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: isExpanded ? '40px' : 'unset',
            color: theme.palette.primary.main,
            justifyContent: 'center'
          }}
        >
          {items[4].icon}
        </ListItemIcon>
        {isExpanded && <ListItemText primary={items[4].label} />}
      </ListItem>
    </List>
  </Drawer>
}

export default LeftMenu
